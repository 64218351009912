.slider {
    height: calc(100vh - 80px);
    width: 99vw;
    position: relative;
    overflow: hidden;

    .container {
        height: 100%;
        width: 300vw;
        display: flex;
        transition: all 1s ease;

        img {
            width: 100vw;
            height: 100%;
            // object-fit: contain;
            object-fit: cover;
        }
    }

    .icons {
        width: fit-content;
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100px;
        margin: auto;
        gap: 10px;

        .icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border: 1px solid #999;
        }
    }
}