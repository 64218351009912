.product {
    padding: 20px 50px;
    display: flex;
    gap: 50px;

    .left {
        flex: 1;
        display: flex;
        gap: 20px;

        .images {
            flex: 1;

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                cursor: pointer;
                margin-bottom: 10px;
            }
        }

        .mainImg {
            flex: 5;

            img {
                width: 100%;
                max-height: 800px;
                object-fit: cover;
            }
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .price {
            font-size: 30px;
            color: #2879FE;
            font-weight: 500;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            text-align: justify;
        }

        .quantity {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
            }
        }

        .add {
            width: 250px;
            padding: 10px;
            background-color: #2879FE;
            color: white;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
        }

        .links {
            display: flex;
            gap: 20px;

            .item {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #2879FE;
                cursor: pointer;
                font-size: 14px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: gray;
            font-size: 14px;
            margin-top: 30px;
        }

        hr {
            border: 1px solid lightgray;
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: 10px;
            // color: gray;
            // font-size: 14px;

            hr {
                width: 200px;
                border: 1px solid lightgray;
            }
        }
    }
}