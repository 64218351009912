.app {
    cursor: default;

    @media (max-width: 1440px) {
        display: none;
    }
}

.mob {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    color: #2879FE;
    text-align: center;

    .center {
        top: 0;
        position: absolute;
        color: black;
        font-size: 30px;
        letter-spacing: 2px;
    }

    @media (min-width: 1440px) {
        display: none;
    }
}

.link {
    color: inherit;
    text-decoration: none;
}