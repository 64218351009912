.categories {
    display: flex;
    height: 80vh;
    gap: 10px;
    margin: 10px;

    .col {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .col-l {
        flex: 2;
    }

    .row {
        flex: 1;
        display: flex;
        gap: 10px;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        button {
            position: absolute;
            min-width: 100px;
            width: fit-content;
            height: 50px;
            padding: 10px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            cursor: pointer;
            border: none;
            background-color: white;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}