.navbar {
    .container {
        position: fixed;
        top: 0;
        width: 100%;
        overflow: hidden;
        z-index: 99;

        .wrapper {
            padding: 10px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            background-color: rgba(255, 255, 255, .15);
            backdrop-filter: blur(5px);
            border-bottom-left-radius: 100px;
            border-bottom-right-radius: 100px;

            .item {
                display: flex;
                align-items: center;
                font-size: 18px;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 25px;
            }

            .center {
                font-size: 30px;
                letter-spacing: 2px;
            }

            .right {
                display: flex;
                gap: 25px;

                .icons {
                    display: flex;
                    gap: 15px;
                    color: #777;
                    cursor: pointer;

                    .cartIcon {
                        position: relative;

                        span {
                            position: absolute;
                            right: -10px;
                            top: -10px;
                            font-size: 12px;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background-color: #2879FE;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}