.card {
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;

    .image {
        width: 100%;
        height: 400px;
        overflow: hidden;
        position: relative;

        &:hover {
            .secondImg {
                z-index: 2;
            }
        }

        span {
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: white;
            color: teal;
            padding: 3px 5px;
            z-index: 3;
            font-size: 500;
            font-size: 12px;
            border-radius: 10px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        .mainImg {
            z-index: 1;
        }
    }

    h2 {
        font-size: 16px;
        font-weight: 400;
    }

    .prices {
        display: flex;
        gap: 20px;

        h3 {
            font-size: 18px;
            font-weight: 500;

            &:first-child {
                color: gray;
                text-decoration: line-through;
            }
        }
    }
}